body {
  height: 100%;

  margin: 0;
  padding: 0;
  --primary: #AF4445;
  color: #383838;

  font-weight: 500;

  font-family: "Avenir Next", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
